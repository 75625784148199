.disable-scroll {
  overflow: hidden;
}

.new-learning-pointer-popup-title {
  text-align: center;
  margin-bottom: 2%;
  margin-top: 2%;
}

.new-learning-pointer-popup-label {
  font-size: 20px;
}

.new-learning-pointer-btn-container {
  margin-top: 4%;
  margin-bottom: 3%;
  display: flex;
  justify-content: space-between;
}

.new-learning-pointer-save-btn,
.new-learning-pointer-cancel-btn {
  margin: 0 5px;
}

.modal-new-learning-pointer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(247,247,247);
  width: 60%;
  height: 70%;
  border: 2px solid black;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
}

.new-learning-pointer-caption {
  margin-top: 10px;
  font-size: 16px; 
  color: #888; 
}

.new-learning-pointer-dropdown {
  margin: 0 2%;
  margin-bottom: 3%;
}

.modal-new-learning-pointer:focus {
  outline: none;
}

.wrap-text {
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 75vw; 
}
.modal-manage-languages {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1%;
  padding-bottom: 2%;
  background-color: rgb(255, 255, 255);
  border: 2px solid black;
  border-radius: 10px;
  width: 30%;
  height: auto;
  margin: auto;
  overflow-y: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
}

.manage-language-left-column {
  display: flex;
  justify-content: space-between;
}

.manage-languages-warnings {
  text-align: center;
}

.manage-languages-save-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
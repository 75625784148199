html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
}

 #root {
  display: flex;
  flex-basis: 100%;
} 

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.navbar {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  height: 127px;
}

.gradient {
  background: linear-gradient(20deg, white, #9fbb8b, #669b41);
  color: #fff;
}

.navbar-expand-lg {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar,
.navbar>.container,
.navbar>.container-fluid {
  position: sticky;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.portfolio-navbar.navbar {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.fixed-top {
  top: 0;
}

.bg-white {
  background-color: #fff !important;
}

.container button {
  margin-right: 15px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 5em;
  min-height: 100%;
}

.page-footer {
  padding-top: 35px;
  border-top: 1px solid #ddd;
  text-align: center;
  padding-bottom: 20px;
  height: 112px;
  padding-bottom: 8%;
}

.page-footer .links,
.page-footer a {
  display: inline-block;
}

.text-center {
  text-align: center !important;
}

.page-footer .social-icons {
  margin-top: 20px;
  margin-bottom: 16px;
}

.page-footer .social-icons a {
  font-size: 18px;
  margin: 0 3px;
  color: #fff;
  border: 1px solid;
  opacity: .75;
  border-radius: 50%;
  width: 36px;
  display: inline-block;
  height: 36px;
  text-align: center;
  background-color: #c5c9d2;
  line-height: 34px;
}

.pages {
  padding-top: 9%;
}

.img-fluid {
  width: 200px;
  margin: 6px;
  padding: 3px;
}

.brand-button {
  background-color: white !important;
  border-color: #4CAF50 !important;
  justify-content: center;
  &.back-button{
    margin-bottom: 1%;
  }
}

.text-right button:first-child {
  margin-right: 10px;
}
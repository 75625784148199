.disable-scroll {
  overflow: hidden;
}

.notes-popup-title {
  text-align: center;
}

.notes-text-area {
  width: 80%;
  height: 80%;
  resize: none;
}

.notes-btns {
  margin-top: 2%;
  display: flex;
  justify-content: center;
}

.notes-popup-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notes-save-btn,
.notes-cancel-btn {
  margin: 0 5px;
}

.modal-notes {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1%;
  border: 2px solid black;
  border-radius: 10px;
  background-color: rgb(247, 247, 247);
  width: 40%;
  height: 60%;
  margin: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.disable-scroll {
  overflow: hidden;
}

.criteria-popup-title {
  text-align: center;
}

.criteria-btns {
  display: flex;
  justify-content: center;
  margin-bottom: 3%;
}

.criteria-btn {
  border: 2px solid black !important;
}

.criteria-levels {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.modal-criteria {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1%;
  padding-right: 2%;
  border: 2px solid black;
  border-radius: 10px;
  background-color: rgb(247, 247, 247);
  width: 40%;
  height: auto;
  margin: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.company-theme {
  background-color: #4CAF50 !important;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  height: 100%;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #aaa;
  cursor: pointer;
}

.modal-body {
  padding: 20px;
  text-align: center;
}

.modal-label {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.modal-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-textarea {
  width: 100%;
  height: 100px;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-select {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-text {
  border: 1px solid #ccc;
  width: 400px;
}

.modal-edit-competency {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(255, 255, 255);
  width: 50%;
  height: 60%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  border: 2px solid #000000;
}

.modal-footer {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  padding: 20px;
  gap: 10px;
  margin-top: 20px;
}
.close-button {
  padding: 10px 20px;
  background-color: #ddd;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.close-button:hover {
  background-color: #ccc;
}
.modal-body h3 {
  margin-bottom: 20px;
}
.table-container {
  margin: 20px 0;
}
.language-table {
  width: 80%;
  margin: 0 auto;
}
.add-language {
  margin-top: 20px;
}
.add-language input {
  width: 200px;
  margin-right: 10px;
}
.publish_label{
  margin-right: 10px;
}

.filter-by-discipline {
  color:black;
  padding-right: 1%;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 2px solid #ddd;
    font-size: 12px;
}

table th,
table td {
    text-align: left;
    padding: 16px;
    border: 2px solid #ddd;
    font-size: 15px;
}

table tr:nth-child(even) {
    background-color: #f2f2f2;
}

table tr:hover {
    background-color: #a9a5a5b0;
}

table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #4CAF50;
    color: white;
}

.th-sticky {
    top: 11.75%;
    position: sticky;
}
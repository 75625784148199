.level-description-table {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  display: flex; 
  justify-content: center; 
  align-items: center;  
}
  
.level-description-table th,
.level-description-table td {
  padding: 4px; 
  border: 1px solid #ccc; 
}
    
td {
  font-size: 12px !important;
}

.disable-scroll {
  overflow: hidden;
}

.language-select-popup-title {
  text-align: center;
  margin-bottom: 5%;
}


.language-select-btns {
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.language-select-popup-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.language-select-save-btn,
.language-select-cancel-btn {
  margin: 0 5px;
}

.modal-language-select {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(247, 247, 247);
  width: 40%;
  height: 30%;
  margin-top: 15%;
  margin-right: 15;
  margin-left: 30%;
  border: 2px solid #000000; 
}

.language-select-caption {
  margin-top: 10px;
  font-size: 16px; 
  color: #888; 
}

.language-select-dropdown {
  margin: 0 2%;
  margin-bottom: 3%;
}

.modal-language-select:focus {
  outline: none;
}
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: white;
  border: 2px solid black;
  padding: 20px;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}


.filter-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-by-discipline {
  color:black;
  padding-right: 5%;
}
.level-button-container {
  display: flex;
  justify-content: space-between;
}

.level-button-inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid black;
  border-radius: 10px;
  width: 100px;
  padding: 10px;
  margin: 5px;
}

.level-button-pending {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20%;
}

.level-button-inner-container.current-level {
  background-color: #a42929;
}

.level-button-inner-container.desired-level {
  background-color: #99cada;
}

.level-button-pending-text {
  color: rgb(0, 0, 0);
  font-family: Arial, sans-serif;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  line-height: 1.5;
}
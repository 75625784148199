.form-label{
  font-size: larger;
  font-weight: bold;
}

.form-input{
  width: 50%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-textarea{
  width: 100%;
  height: 200px;
  padding: 8px;
  margin-bottom: 5%;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-text{
  border: 1px solid #ccc;
  width: 100%;
  color: black;
}


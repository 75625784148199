.quill-editor {
    width: 80%;
    min-height: 35%;
    background-color: white !important;
  }
  
  .ql-container.ql-snow { 
    border: none !important;
  }
  
  .notes-quill-editor {
    width: 80%;
    height: 80%;
    border: 1px solid black;
    border-radius: 5px;
    background-color: white !important;
    overflow: auto;  
  }